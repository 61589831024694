import React, { useState, useEffect, useContext, useCallback } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { getPowerBiEmbedConfig } from "../../api";
import {
  FILTERS_OPERATIONS,
  POWER_BI_SETTINGS,
  TOKEN_TYPE,
} from "../../constants";
import { PropertyStoreContext } from "../../contexts/PropertyStoreContext";
import { getPowerBIPropertyFilter } from "../../constants/utils";
import HTLoader from "../common/ht-loader/HTLoader";

import "./report.scss";

// CSS Class to be passed to the embedded component
const REPORT_CLASS = "report-container";
const ALLOWED_ROUTES = ["/promotions", "/promotions?createPromo=true"];

const Report = () => {
  const { selectedProperty } = useContext(PropertyStoreContext);

  const navigate = useNavigate();
  const [title, setTitle] = useState("");

  // PowerBI Report object (to be received via callback)
  const [report, setReport] = useState();

  // Track Report embedding status
  const [isEmbedded, setIsEmbedded] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const [eventHandlersMap] = useState(
    new Map([
      ["loaded", () => setIsLoaded(true)],
      [
        "error",
        (event) => {
          if (event) {
            console.error(event.detail);
          }
        },
      ],
      [
        "buttonClicked",
        (event) => {
          const { detail: { title } = {} } = event;
          if (title && ALLOWED_ROUTES.includes(title)) {
            setTitle(title);
          }
        },
      ],
    ])
  );

  // Pass the basic embed configurations to the embedded component to bootstrap the report on first load
  // Values for properties like embedUrl, accessToken and settings will be set after fetching the embed config
  const [reportConfig, setReportConfig] = useState({
    type: "report",
    embedUrl: undefined,
    tokenType: TOKEN_TYPE.Embed,
    accessToken: undefined,
    settings: undefined,
  });

  useEffect(() => {
    const embedReport = async () => {
      if (!isEmbedded && selectedProperty?.propertyCode?.length > 0) {
        try {
          const {
            data: {
              data: { embedUrl, embedToken: { token: accessToken } = {} } = {},
            } = {},
          } = await getPowerBiEmbedConfig();

          if (!embedUrl || !accessToken) {
            toast.error("Failed to fetch config for report.");
            setIsEmbedded(true);
            return;
          }

          const filters = [
            getPowerBIPropertyFilter(selectedProperty?.propertyCode),
          ];

          // Update the reportConfig to embed the PowerBI report
          setReportConfig((config) => ({
            ...config,
            embedUrl,
            accessToken,
            filters,
            settings: POWER_BI_SETTINGS,
          }));
        } catch (error) {
          toast.error("Failed to fetch config for report.");
        }

        setIsEmbedded(true);
      }
    };

    embedReport();
  }, [selectedProperty?.propertyCode, isEmbedded]);

  useEffect(() => {
    if (report) {
      report.setComponentTitle("Supplier Report");
    }
  }, [report]);

  useEffect(() => {
    async function fetchData() {
      setTitle("");
      let alignmentScore = 0;
      try {
        const pages = await report.getPages();
        const activePage = pages.find((page) => page.isActive);
        if (activePage) {
          const visuals = await activePage.getVisuals();
          const scoreObj = visuals.find((visual) =>
            visual.title.startsWith("Score:")
          );
          if (scoreObj?.title) {
            const score = parseFloat(scoreObj.title.split(":")[1]);
            alignmentScore = score || 0;
          }
        }
      } catch (error) {
        console.error("Error fetching visuals:", error);
      }
      navigate(title, { state: { alignmentScore: alignmentScore * 100 } });
    }

    if (title) fetchData();
  }, [title, report, navigate]);

  const applyFilter = useCallback(
    async (filter) => {
      try {
        await report.updateFilters(FILTERS_OPERATIONS.ReplaceAll, [filter]);
      } catch (error) {
        error && toast.error(error);
      }
    },
    [report]
  );

  useEffect(() => {
    if (!report) {
      return;
    }

    const filter = getPowerBIPropertyFilter(selectedProperty?.propertyCode);

    (async () => await applyFilter(filter))();
  }, [selectedProperty, report, applyFilter]);

  const handleEmbedComponent = (embedObject) => {
    setReport(embedObject);
  };

  return (
    <>
      {!isLoaded && <HTLoader />}
      <PowerBIEmbed
        embedConfig={reportConfig}
        cssClassName={REPORT_CLASS + (isLoaded ? "" : " invisible")}
        getEmbeddedComponent={handleEmbedComponent}
        eventHandlers={eventHandlersMap}
      />
    </>
  );
};

export default Report;
