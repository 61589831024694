import PromoTitle from "./PromoTitle";
import "./create-promo.scss";
import { Button } from "reactstrap";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CannedPromos from "./CannedPromos";
import TargetingConditions from "./TargetingConditions";
import RoomsRateplans from "./RoomsRateplans";
import OfferType from "./OfferType";
import Summary from "./Summary";
import { PropertyStoreContext } from "../../contexts/PropertyStoreContext";
import { savePromo } from "../../api";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

const CreatePromo = ({
  promo,
  setPromo,
  stepperChangeCallback,
  ratePlans,
  rooms,
  isEdit,
  hideModal,
  setLoading,
  calculationType,
  setCalculationType,
}) => {
  const { selectedProperty } = useContext(PropertyStoreContext);
  const [stepCount, setStepCount] = useState(isEdit ? 5 : 0);
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);
  const [tc, setTc] = useState({
    showBooking: !!(
      promo?.rateRule?.ruleVO?.bookingVO?.bookingStartDate ||
      promo?.rateRule?.ruleVO?.bookingVO?.bookingEndDate
    ),
    showPSD: !!(
      promo?.rateRule?.ruleVO?.stayVO?.stayStartDate ||
      promo?.rateRule?.ruleVO?.stayVO?.stayEndDate
    ),
    showBlackouts:
      !!promo?.rateRule?.ruleVO?.stayVO?.blackOutDateRanges?.length,
    showLT: !!(
      promo?.rateRule?.ruleVO?.bookingVO?.minBookingLeadTime ||
      promo?.rateRule?.ruleVO?.bookingVO?.maxBookingLeadTime
    ),
    showLOS: !!(
      promo?.rateRule?.ruleVO?.stayVO?.minLengthOfStay ||
      promo?.rateRule?.ruleVO?.stayVO?.maxLengthOfStay
    ),
  });

  const handleFieldChange = useCallback(
    (fieldPath, value) => {
      setPromo((prevPromo) => {
        const updatedObject = { ...prevPromo };
        const pathArray = fieldPath.split(".");

        // Traverse through the path to update the nested field
        let nestedObject = updatedObject;
        for (let i = 0; i < pathArray.length - 1; i++) {
          const key = pathArray[i];
          if (nestedObject[key] == null) {
            nestedObject[key] = {};
          }
          nestedObject = nestedObject[key];
        }
        nestedObject[pathArray[pathArray.length - 1]] = value;
        return updatedObject;
      });
    },
    [setPromo]
  );

  useEffect(() => {
    if (!isEdit) {
      const selectedList = [];

      rooms.forEach((room) => {
        ratePlans.forEach((rp) => {
          selectedList.push({ mLeft: rp.code, mRight: room.code });
        });
      });
      handleFieldChange("allowedRateRoomCodes", selectedList);
    }
  }, [isEdit, rooms, ratePlans, handleFieldChange]);

  useEffect(() => {
    handleFieldChange("propertyCode", selectedProperty.propertyCode);
  }, [selectedProperty, handleFieldChange]);

  const stepChange = (type) => {
    setIsNextBtnDisabled(false);
    if (type === "next") {
      setStepCount((prev) => prev + 1);
    } else {
      setStepCount((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (stepperChangeCallback) {
      stepperChangeCallback(stepCount);
    }
  }, [stepCount, stepperChangeCallback]);

  const submitPromo = (isDraft = false) => {
    setLoading(true);
    const token = localStorage.getItem("ROCP_idToken");
    savePromo(
      {
        ...promo,
        eventType: isEdit ? "Promo Modified" : "Promo Created",
        username: jwtDecode(token).username,
      },
      isDraft,
      calculationType
    )
      .then((res) => {
        if (res?.data?.success) {
          hideModal();
          if (res?.data?.data?.includes("Your promo is being created")) {
            toast.success(
              `Your promo is being ${isEdit ? "edited" : "created"}, it may take a minute or two to appear in our active promo list`
            );
          } else {
            const saveType = isDraft ? "saved as draft" : "saved";
            toast.success(`Promo ${isEdit ? "edited" : saveType} successfully`);
          }
        } else {
          toast.error(`Failed to save promo, error: ${res?.data?.error}`);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Failed to save promo");
      });
  };
  const getPromoStep = () => {
    switch (stepCount) {
      case 0:
        return (
          <CannedPromos
            stepChange={stepChange}
            fieldChange={handleFieldChange}
            setTc={setTc}
          />
        );
      case 1:
        return (
          <PromoTitle
            setIsNextBtnDisabled={setIsNextBtnDisabled}
            fieldValue={promo?.rateRule?.ruleVO?.descriptionVO?.name || ""}
            onFieldChange={handleFieldChange}
          />
        );
      case 2:
        return (
          <TargetingConditions
            tc={tc}
            setTc={setTc}
            promo={promo}
            handleFieldChange={handleFieldChange}
            setIsNextBtnDisabled={setIsNextBtnDisabled}
          />
        );
      case 3:
        return (
          <OfferType
            promo={promo}
            onFieldChange={handleFieldChange}
            setIsNextBtnDisabled={setIsNextBtnDisabled}
            calculationType={calculationType}
            setCalculationType={setCalculationType}
          />
        );
      case 4:
        return (
          <RoomsRateplans
            promo={promo}
            handleFieldChange={handleFieldChange}
            isEdit={isEdit}
            ratePlans={ratePlans}
            rooms={rooms}
            setIsNextBtnDisabled={setIsNextBtnDisabled}
          />
        );
      case 5:
        return (
          <Summary
            setIsNextBtnDisabled={setIsNextBtnDisabled}
            promo={promo}
            isEdit={isEdit}
            onFieldChange={handleFieldChange}
            ratePlans={ratePlans}
            rooms={rooms}
            tc={tc}
            setTc={setTc}
            calculationType={calculationType}
            setCalculationType={setCalculationType}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="create-promo">
      <div className="create-promo-body">{getPromoStep()}</div>
      {stepCount > 0 && stepCount < 6 && (
        <div className="create-promo-footer">
          {stepCount < 5 && (
            <Button
              color="primary"
              onClick={() => stepChange("next")}
              className="next-btn"
              disabled={isNextBtnDisabled}
            >
              Next
            </Button>
          )}

          {stepCount === 5 && (
            <div
              className="create-promo-footer summary-footer"
              style={{ cursor: isNextBtnDisabled ? "not-allowed" : "" }}
            >
              <Button
                color="primary"
                onClick={() => submitPromo(false)}
                className="ignore-btn"
                disabled={isNextBtnDisabled}
              >
                Save & continue
              </Button>
              <Button
                onClick={() => submitPromo(true)}
                className="save-as-btn"
                disabled={isNextBtnDisabled}
              >
                Save as draft
              </Button>
            </div>
          )}

          {stepCount < 5 && (
            <Button color="link" onClick={stepChange} className="go-back-btn">
              Go back
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default CreatePromo;
