import Loader from "../../components/Loader";
import { Col, Container, FormGroup, Input, Row } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import "./promotions.scss";
import PromotionsKPIs from "./PromotionsKPIs";
import { PropertyStoreContext } from "../../contexts/PropertyStoreContext";
import { DATE_FILTER_OPTIONS } from "../../constants/utils";
import PromotionsTable from "./PromotionsTable";
import PromotionCreatePromo from "./PromotionCreatePromo";
import PieCh from "../../components/charts/Pie";
import {
  activatePromo,
  disablePromo,
  getPromo,
  getRatePlansByProperty,
  getRoomsByProperty,
} from "../../api";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const Promotions = () => {
  const { selectedProperty } = useContext(PropertyStoreContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState("LAST_7_DAYS");
  const [ratePlans, setRatePlans] = useState([]);
  const [rooms, setRooms] = useState([]);

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [promo, setPromo] = useState({});
  const [calculationType, setCalculationType] = useState();
  const [isGetPromos, setIsGetPromos] = useState({});

  const handleRangeChange = (e) => {
    setRange(e.target.value);
  };

  useEffect(() => {
    if (selectedProperty) {
      setLoading(true);
      Promise.all([
        getRatePlansByProperty(selectedProperty.propertyId),
        getRoomsByProperty(selectedProperty.propertyId),
      ]).then((response) => {
        setRatePlans(response[0].data.data);
        setRooms(response[1].data.data);
        setLoading(false);
      });
    }
  }, [selectedProperty]);

  const openEditModal = (promo) => {
    setLoading(true);
    getPromo(promo.id, selectedProperty.propertyId).then(
      (response) => {
        setModal(true);
        setPromo((previouyPromo) => ({
          ...previouyPromo,
          ...response?.data?.data?.promoCodeVO,
        }));
        setCalculationType(response?.data?.data?.calculationType);
        setIsEdit(true);
        setLoading(false);
      },
      () => {
        setLoading(false);
        toast.error("Fail to load promo");
      }
    );
  };

  const onDisablePromo = (promo) => {
    setLoading(true);
    disablePromo(promo.id, selectedProperty.propertyId).then(
      (response) => {
        setIsGetPromos({});
        setLoading(false);
        toast.success("Disabled promo successfully");
      },
      () => {
        setLoading(false);
        toast.error("Fail to disable promo");
      }
    );
  };

  const onActivatePromo = (promo) => {
    setLoading(true);
    activatePromo(promo.id, selectedProperty.propertyId).then(
      (response) => {
        setLoading(false);
        setIsGetPromos({});
        toast.success("Activated promo successfully");
      },
      () => {
        setLoading(false);
        toast.error("Fail to activate promo");
      }
    );
  };

  useEffect(() => {
    if (location.state?.alignmentScore) {
      setPromo((previousPromo) => ({
        ...previousPromo,
        alignmentScore: location.state?.alignmentScore,
      }));
      navigate(location, { replace: true });
    }
  }, [location, navigate]);

  return (
    <Container
      id="promohome"
      data-heap-id="promohome"
      fluid
      className="promotions-container"
    >
      <Loader loading={loading} />
      <Row>
        <Col md={6} className="mt-4">
          <div className="promotions-label">
            Promotions{" "}
            <span className="promotions-label-time">
              Track , manage and delete promos
            </span>
          </div>
        </Col>
        <Col md={12}>
          <div className="promotions-filter">
            <span>show</span>
            <FormGroup className="promotions-filter-form">
              <Input
                type="select"
                name="select"
                id="promotions-filter-select"
                style={{ border: "none" }}
                value={range}
                onChange={handleRangeChange}
              >
                {DATE_FILTER_OPTIONS?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </div>
        </Col>
      </Row>
      <PromotionsKPIs
        setLoading={setLoading}
        range={range}
        selectedProperty={selectedProperty}
      />
      <Row>
        <Col md={8}>
          <PromotionsTable
            selectedProperty={selectedProperty}
            onEdit={openEditModal}
            onDisable={onDisablePromo}
            onActivate={onActivatePromo}
            isGetPromosList={isGetPromos}
          />
        </Col>
        <Col md={4}>
          <PromotionCreatePromo
            ratePlans={ratePlans}
            rooms={rooms}
            modal={modal}
            setModal={setModal}
            promo={promo}
            setPromo={setPromo}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setIsGetPromos={setIsGetPromos}
            setLoading={setLoading}
            selectedProperty={selectedProperty}
            calculationType={calculationType}
            setCalculationType={setCalculationType}
            property={selectedProperty}
          />
          <PieCh selectedProperty={selectedProperty} />
        </Col>
      </Row>
    </Container>
  );
};

export default Promotions;
